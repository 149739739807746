<template>
  <div class="container">
    <h2 class="results-header" v-if="resultsHeader.length > 0">{{resultsHeader}}</h2>

    <LoadingSpinner v-if="isSearching" class="text-secondary" loading_text="searching" style="margin-top: 50px;"></LoadingSpinner>

    <FilmList :films="filmlist" :show_years="true" />
  </div>
</template>


<script>
import axios from "axios";
import FilmList from "@/components/FilmChecklist.vue";
import {Film} from "@/film";
import LoadingSpinner from "@/components/LoadingSpinner.vue";

export default {
  name: "SearchResultsPage",
  components: {LoadingSpinner, FilmList},
  data() {
    return {
      isSearching: true,
      resultsHeader: "",
      filmlist: []
    }
  },

  created() {

    this.$watch(
        () => this.$route.query,
        (/*newText, oldText*/) => {
          this.doSearch();
        }
    );

    this.doSearch();

    // EventBus.on('search-term-updated', (_type, _text) => {
    //   console.log(`search-term-updated ( "${_type}", "${_text}" )`);
    //   this.doSearch();
    // });
  },

  methods: {
    async doSearch() {

      this.filmlist = [];

      const searchType = this.$route.query.searchType;
      const searchText = this.$route.query.searchText;

      if ( !searchType || searchType === '' || !searchText || searchText === '') {
        this.resultsHeader = `no results`;
        this.isSearching = false;
        return;
      }

      this.resultsHeader = `Searching for ${searchType} containing "${searchText}"`;
      this.isSearching = true;

      axios.get(`/api/film/search/${searchType.toLowerCase()}/${encodeURIComponent(searchText)}`)
          .then((response) => {
            this.filmlist = response.data.map(f => new Film(f));
            this.resultsHeader = `${this.filmlist.length} film${this.filmlist.length != 1 ? 's' : ''} with ${searchType} containing "${searchText}"`;
            //console.log(this.resultsHeader)
            Film.sortFilmsByPrioirity(this.filmlist);
          })
          .catch((err) => {
            console.log(err);
          })
          .finally(() => {
            this.isSearching = false;
          })
    }
  }

}
</script>


<style scoped>

.search-form {
  margin: auto;
  .btn {
    //margin-left: 10px;
  }
}

.search-button {
  padding-left: 10px;
}

.search-dropdown {
  margin-left: -20px;
}

.results-header {
  padding-bottom: 20px;
}

</style>