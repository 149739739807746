<template>
  <div class="ceremony">

    <!-- ceremony header -->
    <h5 class="text-secondary ceremony-header">
      <a href="/checklist" @click.prevent="navigateToChecklist(ceremony_info.ceremony)">
        {{getCeremonyHeader(ceremony_info)}}
      </a>
    </h5>

    <!-- ceremony needs review message -->
    <div v-if="showNeedsReviewMessage" class="review-ceremony-message">
      <a href="/checklist" @click.prevent="navigateToChecklist(ceremony_info.ceremony)">
        Start Tracking Progress by Reviewing <br>the {{getCeremonyOrdinalText(ceremony_info.ceremony)}} Ceremony Checklist
      </a>
    </div>

    <!-- category rows -->
    <div>
      <div v-for="row in numRowsToDisplay" :key="row-1" :class="{'category-row': true, 'category-row-review-required': showNeedsReviewMessage }">
        <AchievementGrapic
            v-for="(cat) in rowCategories[row-1]"
            :key="cat.category_name"
            :film_ids="cat.films.map(film_obj => film_obj.film_id)"
            :category_name="cat.category_name"
            :ceremony="ceremony_info.ceremony"
            :size="cat.normalized_category_name.toUpperCase() === 'BEST PICTURE' ? '60' : '44'"
            :disabled="showNeedsReviewMessage"
        >
        </AchievementGrapic>
      </div>
    </div>

    <div class="ceremony-footer">
      <div class="btn btn-sm btn-show-more" :class="{inactive: showNeedsReviewMessage}" type="button" @click="showExpanded = !showExpanded">
        <i :class="showExpanded? 'fa fa-caret-up' : 'fa fa-caret-down'" aria-hidden="true"></i>
        <span v-text="showExpanded? ' hide all but top row ' : ' show all categories '" aria-hidden="true"></span>
      </div>
    </div>

  </div>
</template>


<script>
import AchievementGrapic from "@/components/AchievementGrapic.vue";
import {Film} from "@/film";
import {userStore} from "@/userStore";

export default {
  name: "CeremonyCard",
  components: {AchievementGrapic},
  props: ['ceremony_info'],
  data() {
    return {
      CEREMONY_REVIEW_MESSAGE_ENABLED: false,
      rowCategories: [],
      showExpanded: false,
    }
  },
  computed : {
    numRowsToDisplay() {
      return ( this.showExpanded? this.rowCategories.length : Math.min(1, this.rowCategories.length) );
    },
    showNeedsReviewMessage() {
      return this.CEREMONY_REVIEW_MESSAGE_ENABLED && !userStore.isCeremonyReviewed(this.ceremony_info.ceremony);
    },
  },
  created() {
    const categoryGroups = [
      // top group will always occupy the first row, and be these five categories
      ["Actor in a Leading Role", "Actor in a Supporting Role", "Best Picture", "Actress in a Leading Role", "Actress in a Supporting Role"],

      // remaining categories will be on their own, with some pairs kept together
      ["Documentary Feature Film"],
      ["Animated Feature Film"],
      ["International Feature Film"],
      ["Directing", "Cinematography"],
      ["Writing (Original Screenplay)", "Writing (Adapted Screenplay)"],
      ["Music (Original Score)", "Music (Original Song)"],
      ["Visual Effects"],
      ["Production Design"],
      ["Film Editing"],
      ["Sound"],
      ["Makeup and Hairstyling", "Costume Design"],
      ["Live Action Short Film", "Documentary Short Film", "Animated Short Film"],
      ["Honorary Award"],
    ];

    const MAX_CATEGORIES_PER_ROW = 7;
    this.rowCategories = [];
    categoryGroups.forEach((categoryGroup, index) => {

      const nominationCategories = this.getNominationCategoriesForGroup(categoryGroup);

      // first row will always contain the first category group, and nothing else
      if (index === 0) {
        this.rowCategories.push(nominationCategories);
        this.rowCategories.push([]); // start next row

        // subsequent rows will squeeze as many category groups as well fit without exceeding the max
      } else {
        const rowIndex = this.rowCategories.length - 1;

        // if this is the first group added to a new row, just add it
        if (this.rowCategories[rowIndex].length === 0) {
          this.rowCategories[rowIndex] = this.rowCategories[rowIndex].concat(nominationCategories);

        // subsequent groups will be added to this row if they'll fit
        } else if ((this.rowCategories[rowIndex].length + nominationCategories.length) <= MAX_CATEGORIES_PER_ROW) {
          this.rowCategories[rowIndex] = this.rowCategories[rowIndex].concat(nominationCategories);

        // if it won't fit, start a new row
        } else {
          this.rowCategories.push(nominationCategories);
        }
      }
    });
  },
  mounted() {
    //this.log(this.ceremony_info);
  },

  methods: {
    log(_string) {
      if (this.ceremony_info.ceremony === 96) {
        console.log(_string);
      }
    },
    getCeremonyOrdinalText(_ceremony) {
      return Film.getCeremonyOrdinalText(_ceremony);
    },
    getCeremonyHeader(ceremony_info) {
      const kMonthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
      const date = Film.getDateOfCeremony(ceremony_info.ceremony);
      return `${this.getCeremonyOrdinalText(ceremony_info.ceremony)} Ceremony • ${kMonthNames[date.getUTCMonth()]} ${date.getUTCDate()} ${date.getUTCFullYear()}`;
    },
    navigateToChecklist(_ceremony) {
      this.$router.push({
        name: 'ChecklistPage',
        params: {init_ceremony: _ceremony}
      });
    },

    getNominationCategoriesForGroup(_normalizedCategoryNames) {

      // filter the categories to just ones in the given argument
      let filteredCategories = this.ceremony_info.nomination_summary.filter((cat) => {
        return _normalizedCategoryNames.some((categoryInRow) => {
          return (categoryInRow === cat.normalized_category_name);
        });
      });

      // sort the categories so they're in the order represented by the row array above
      filteredCategories.sort((cat1, cat2) => {
        const index1 = _normalizedCategoryNames.findIndex((name) => {
          return (name === cat1.normalized_category_name);
        });
        const index2 = _normalizedCategoryNames.findIndex((name) => {
          return (name === cat2.normalized_category_name);
        });
        return index1 - index2;
      });

      return filteredCategories;
    }
  }
}

</script>



<style scoped>
.ceremony {

  .ceremony-header {
    text-align: center;
    margin-bottom: 1em;
    background-color: transparent;
    /*border: none;*/
  }

  margin-top: 10px;
  margin-bottom: 10px;
  margin-right: auto;
  margin-left: auto;
  max-width: 450px;

  padding: 5px;

  border-style: none none solid none;
  border-color: lightgrey;
  border-width: 1px;

  .category-row {
    width: fit-content;
    padding-bottom: 10px;
    margin-left: auto;
    margin-right: auto;
  }

  .ceremony-footer {
    background-color: transparent;
    border: none;
    text-align: center;
  }

  .btn-show-more {
    padding: 0;
    font-size: x-small;
    color: var(--bs-secondary);
    vertical-align: top;

    &.inactive {
      opacity: 20%;
      pointer-events: none;
    }
  }
}

.review-ceremony-message {

  text-align: center;
  font-weight: 500;
  font-size: small;
  z-index: 1;
  position: relative;

  a {
    position: absolute;
    top: 1.5em;
    left: 0;
    width: 100%;
    /*color: var(--bs-secondary); */
    color: var(--mol-color-gold)
  }

  a:hover {
    color: var(--bs-link-hover-color);
  }
}

a {
  text-decoration: inherit;
  color: var(--bs-secondary);
  cursor: pointer;
}
a:hover {
  color: var(--bs-link-hover-color);
}
</style>