<template>
  <span class="badge rounded-pill bg-danger"><i class="fa fa-exclamation" aria-hidden="true"></i></span>
  <div class="card card-body invite-received-card">
    <FriendCard_Stranger :friend="friend">
    </FriendCard_Stranger>

    <div class="text-center">
      <div class="invite-text">
        <p>confirm connection invite from</p>
        <p style="" class="friend-name">{{friend.username}}</p>
      </div>
      <button class="btn btn-sm btn-outline-danger" type="button" @click="show_confirmation_modal=true">
        <i class="fa fa-times"></i> delete
      </button>
      <button class="btn btn-sm btn-success me-sm-2" type="button" :disabled="buttonDisabled" @click="acceptConnectionRequest()">
        <i class="fa fa-user-plus"></i> accept
      </button>
    </div>
  </div>

  <GenericModal v-if="show_confirmation_modal" @modal-closed="show_confirmation_modal=false">
    <div class="text-center">
      <div>
        <p>Are you sure you want to delete this invite from <br><strong>{{friend.username}}</strong>?</p>
      </div>
      <button class="btn btn-sm btn-primary" type="button" data-bs-dismiss="modal">
        <i class="fa fa-user"></i> keep invitation
      </button>
      <button class="btn btn-sm btn-danger me-sm-2" type="button" data-bs-dismiss="modal" @click="ignoreConnectionRequest()">
        <i class="fa fa-user-times"></i> delete invitation
      </button>
    </div>
  </GenericModal>
</template>


<script>
import axios from "axios";
import {userStore} from "@/userStore";
import FriendCard_Stranger from "@/components/friends/cards/FriendCard-Stranger.vue";
import GenericModal from "@/components/modal/GenericModal.vue";

export default {
  name: 'FriendCard_InviteReceived',
  components: {GenericModal, FriendCard_Stranger},
  props: ['friend'],
  emits: ['update-friends-list'],
  data() {
    return {
      show_confirmation_modal: false
    }
  },
  computed: {
    user() {
      return userStore.user;
    },
    buttonDisabled() {
      return ( this.user._id === this.friend._id );
    }
  },
  methods: {
    async acceptConnectionRequest() {
      if ( !this.friend?._id ) {
        return;
      }
      axios.get(`/api/friend/accept/${this.friend._id}`)
          .then((response) => {
            userStore.setUser(response.data);
            this.$emit('update-friends-list');
          })
          .catch((err) => {
            console.log(err);
          })
          .finally(() => {
            this.goToFriendsPage();
          });
    },

    async ignoreConnectionRequest() {
      if ( !this.friend?._id ) {
        return;
      }
      axios.get(`/api/friend/remove-relationship-with/${this.friend?._id}`)
          .then((response) => {
            userStore.setUser(response.data);
            console.log(`update-friends-list`)
            this.$emit('update-friends-list');
          })
          .catch((err) => {
            console.log(err);
          });
    },

    goToFriendsPage() {
      this.$router.push({name: 'FriendsPage'});
    }
  }

}
</script>


<style scoped>

.badge {
  position: absolute;
  margin-top: -8px;
  margin-left: 12px;
  animation: pulse-animation 2s infinite;
  z-index: 1;
}

.invite-received-card {
  background-color: HoneyDew;
  border-color: var(--bs-success);
  margin: 20px;
}

button {
  margin-right: 5px;
  margin-left: 5px;
}

.invite-text {
  text-align: center;
  margin-bottom: 10px;

  p {
    margin-bottom: 0px;

    &.friend-name {
      font-weight: 500;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }
  }
}

@keyframes pulse-animation {
  0% { box-shadow: 0 0 0 0px rgba(255, 127, 80, 0.4); }
  100% { box-shadow: 0 0 0 15px rgba(255, 127, 80, 0); }
}

</style>