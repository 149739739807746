<template>
  <div id="snackbar" ref="snackbar">
    <img v-if="displayImage" :src=displayImage class="toast-image">
    {{displayMessage}}
  </div>
</template>


<script>
import {EventBus} from "@/eventBus";

export default {
  name: "ToastNotification",

  data() {
    return {
      messageQueue: []
    }
  },

  created() {
    EventBus.on('toast-notification', (_message, _image) => {
      this.messageQueue.push({message: _message, image: _image? _image : 'logo.svg' });
      if ( this.messageQueue.length === 1 ) {
        this.showToast();
      }
    });
  },
  computed: {
    displayMessage() {
      return this.messageQueue.length? this.messageQueue[0].message : '';
    },
    displayImage() {
      return this.messageQueue.length? process.env.BASE_URL + this.messageQueue[0].image : null;
    }
  },
  methods: {
    showToast() {
      this.$refs.snackbar.classList.add('show');
      setTimeout(this.hideToast, 2900);
    },
    hideToast() {
      this.$refs.snackbar.classList.remove('show');
      this.messageQueue.shift();
      if ( this.messageQueue.length ) {
        setTimeout(this.showToast, 100);
      }
    }
  }
}
</script>

<style scoped>
  #snackbar {
    visibility: hidden;
    /*min-width: 250px;*/
    width: 250px;

    /*left: 50%;
    margin-left: -125px;*/
    right: 20px;

    background-color: var(--mol-color-gold);
    color: var(--bs-body-color);
    text-align: center;
    border-radius: 2px;
    padding: 16px;
    position: fixed;
    z-index: 1;

    bottom: 30px;
    font-size: 17px;
  }

  #snackbar.show {
    visibility: visible;
    -webkit-animation: fadein 0.5s, fadeout 0.5s 2.5s;
    animation: fadein 0.5s, fadeout 0.5s 2.5s;
  }

  @-webkit-keyframes fadein {
    from {bottom: 0; opacity: 0;}
    to {bottom: 30px; opacity: 1;}
  }

  @keyframes fadein {
    from {bottom: 0; opacity: 0;}
    to {bottom: 30px; opacity: 1;}
  }

  @-webkit-keyframes fadeout {
    from {bottom: 30px; opacity: 1;}
    to {bottom: 0; opacity: 0;}
  }

  @keyframes fadeout {
    from {bottom: 30px; opacity: 1;}
    to {bottom: 0; opacity: 0;}
  }

  .toast-image {
    width: 32px;
    float: left;
  }
</style>