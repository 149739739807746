<template>
  <CategoryLink :category="category_name" :ceremony="ceremony" :disabled="disabled">
    <div class="achievement-graphic" :class="{disabled: disabled}" :style="`width:${size}px`">

        <svg :width="size" :height="size">
          <circle
              v-for="(film_id, index) in this.unique_film_ids"
              :key="film_id"
              :transform="`rotate(${index*(360.0/numFilms)}, ${center}, ${center})`"
              :cx="center" :cy="center"
              :r="radius"
              fill="transparent"
              :stroke="dashStrokeColor(index)"
              :stroke-width="index === 0 ? topRingWidth : ringWidth"
              :stroke-dasharray="`${dashStrokeLength} ${dashSpaceLength}`"
              :stroke-dashoffset="strokeDashOffest"
          />

          <image
              v-if="getShouldDisplayCheckmark"
              :x="center-(checkmarkSize*0.5)"
              :y="center-(checkmarkSize*0.5)"
              :width="checkmarkSize"
              :height="checkmarkSize"
              :href="`${publicPath}gold-check.svg`"
          />
        </svg>

        <div v-if="size>=40" v-html="categoryDisplayNameHtml" class="category-name" :class="{disabled: disabled}"></div>
    </div>
  </CategoryLink>
</template>


<script>
import { userStore } from "@/userStore";
import CategoryLink from "@/components/CategoryLink.vue";
export default {
  name: "NominationGraphic",
  components: {CategoryLink},
  props: ['category_name', 'ceremony', 'film_ids', 'size', 'disabled'],
  data() {
    return {
      publicPath: process.env.BASE_URL,
      show_modal: false,
      winner_on_top: false,
      unique_film_ids: this.film_ids.filter((film_id, idx, array) => array.indexOf(film_id) === idx)
    }
  },
  computed: {
    categoryDisplayNameHtml() {
      return this.category_name

          // display in all-caps
          .toUpperCase()

          // add word-splitting suggestions
          .replace('CINEMATOGRAPHY', 'CINEMA&shy;TOGRAPHY')
          .replace('INTERNATIONAL', 'INTER&shy;NATIONAL')

          // shorten some of these older, long-winded category names
          .replace('(SCREENPLAY WRITTEN DIRECTLY FOR THE SCREEN)', '(ORIGINAL SCREENPLAY)')
          .replace('(STORY AND SCREENPLAY--WRITTEN DIRECTLY FOR THE SCREEN)', '(ORIGINAL SCREENPLAY)')
          .replace('(SCREENPLAY WRITTEN DIRECTLY FOR THE SCREEN--BASED ON FACTUAL MATERIAL OR ON STORY MATERIAL NOT PREVIOUSLY PUBLISHED OR PRODUCED)', '(ORIGINAL SCREENPLAY)')
          .replace('(STORY AND SCREENPLAY--BASED ON FACTUAL MATERIAL OR MATERIAL NOT PREVIOUSLY PUBLISHED OR PRODUCED)', '(ORIGINAL SCREENPLAY)')
          .replace('(STORY AND SCREENPLAY--BASED ON MATERIAL NOT PREVIOUSLY PUBLISHED OR PRODUCED)', '(ORIGINAL SCREENPLAY)')


          .replace('(SCREENPLAY BASED ON MATERIAL PREVIOUSLY PRODUCED OR PUBLISHED)', '(ADAPTED SCREENPLAY)')
          .replace('(SCREENPLAY--BASED ON MATERIAL FROM ANOTHER MEDIUM)', '(ADAPTED SCREENPLAY)')
          .replace('(SCREENPLAY BASED ON MATERIAL FROM ANOTHER MEDIUM)', '(ADAPTED SCREENPLAY)')
          .replace('(SCREENPLAY ADAPTED FROM OTHER MATERIAL)', '(ADAPTED SCREENPLAY)')

          .replace('(MUSIC SCORE OF A DRAMATIC OR COMEDY PICTURE)', '(SCORE)')
          .replace('(MUSIC SCORE OF A DRAMATIC PICTURE)', '(SCORE)')
          .replace('(SCORING OF A MUSICAL PICTURE)', '(MUSICAL SCORE)')

          .replace('(ORIGINAL SONG SCORE AND ITS ADAPTATION -OR- ADAPTATION SCORE)', '(ADAPTATION OR ORIGINAL SONG SCORE)')
          .replace('(ORIGINAL SONG SCORE AND ITS ADAPTATION OR ADAPTATION SCORE)', '(ADAPTATION OR ORIGINAL SONG SCORE)')
          .replace('(SCORING: ORIGINAL SONG SCORE AND ADAPTATION -OR- SCORING: ADAPTATION)', '(ADAPTATION OR ORIGINAL SONG SCORE)')
          .replace('(SCORING: ADAPTATION AND ORIGINAL SONG SCORE)', '(ADAPTATION OR ORIGINAL SONG SCORE)')
          .replace('(SCORE OF A MUSICAL PICTURE--ORIGINAL OR ADAPTATION)', '(ADAPTATION OR ORIGINAL SONG SCORE)')

          .replace('(SCORING OF MUSIC--ADAPTATION OR TREATMENT)', '(ADAPTED MUSIC SCORE)')

          .replace('(ORIGINAL SCORE--FOR A MOTION PICTURE [NOT A MUSICAL])', '(ORIGINAL MUSIC SCORE)')
          .replace('(MUSIC SCORE--SUBSTANTIALLY ORIGINAL)', '(ORIGINAL MUSIC SCORE)')

          .replace('(BLACK-AND-WHITE)', '(B&W)')
          .replace('SPECIAL ACHIEVEMENT AWARD', 'SPECIAL ACHIEVEMENT')

          //.replace('', '')
          ;
    },

    numFilms() {
      return this.unique_film_ids.length;
    },
    numViewed() {
      return this.unique_film_ids.filter((film_id => userStore.isFilmMarkedViewed(film_id))).length;
    },
    ringWidth() {
      return (this.size * 0.12);
    },
    topRingWidth() {
      return (this.ringWidth * 1.0);
    },
    center() {
      return (this.size / 2.0);
    },
    diameter() {
      return (this.size - Math.max(this.ringWidth, this.topRingWidth));
    },
    radius() {
      return (this.diameter / 2.0);
    },
    circumference() {
      return (this.diameter * Math.PI);
    },
    dashStrokeBorderSize() {
      const maxBorderSize = this.circumference / 180.0;
      const proportionallySizedBorder = this.circumference / (this.numFilms * 5.0);
      return ( (this.numFilms === 1)? 0 : Math.min(maxBorderSize, proportionallySizedBorder) );
    },
    dashStrokeLength() {
      return (this.circumference / this.numFilms) - this.dashStrokeBorderSize;
    },
    dashSpaceLength() {
      return (this.circumference - this.dashStrokeLength);
    },
    strokeDashOffest() {
      if ( this.winner_on_top ) {
        return (this.circumference/4 + this.dashStrokeLength/2.0);
      }
      return (this.circumference/4);
    },

    getShouldDisplayCheckmark() {
      if ( this.disabled ) {
        return false;
      }
      return (this.numViewed === this.numFilms);
    },

    checkmarkSize() {
      return this.size * 0.4;
    }
  },
  methods: {
    dashStrokeColor(_index) {

      const colorUnviewed = '#efefef';
      const colorTopViewed = '#d2b127';
      const colorViewed = '#e1bb28';

      if ( this.disabled ) {
        return colorUnviewed;
      }

      if ( this.winner_on_top ) {

        const viewedFirstFilm = this.numViewed(this.unique_film_ids[0]);

        if ( _index == 0 ) {
          return viewedFirstFilm ? colorTopViewed : colorUnviewed;
        }
        const numNonWinnersViewed = this.numViewed - (viewedFirstFilm? 1 : 0);
        return (_index > numNonWinnersViewed)? colorUnviewed : colorViewed;
      }

      return ( (_index < this.numViewed)? colorViewed : colorUnviewed );
    }
  }
}
</script>


<style scoped>
.achievement-graphic {
  display: inline-grid;
  margin: 2px;
  text-align: center;

  &.disabled {
    opacity: 50%;
  }
}

.category-name {
    font-size: 6px;
    margin-top: 1em;
    margin-bottom: 0;

    &.disabled {
      opacity: 30%;
    }
}
</style>