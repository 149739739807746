<template>
  <!-- film info -->
  <div v-if="film" style="margin: 15px">
    <div class="row">
      <div>
        <img class="poster-image" :src="film.poster_url" >
        <LanguageIcon class="language-icon" v-if="film.original_language !== 'en'" :language_code="film.original_language" :height="15"></LanguageIcon>
        <span style="font-weight: 500;">{{ film.genreText(' • ') }} • {{ film.runtimeText() }}</span>
        <div>{{film.tmdb.overview}}</div>
      </div>

      <ViewedByFriendsCard :film_id="film_id"></ViewedByFriendsCard>

      <div class="row" style="padding: 10px 20px 10px 20px;">
        <ViewChoiceIcons :streaming_providers="film.streamingProviders" :imdb_link="film.imdbLink()"></ViewChoiceIcons>
      </div>

      <hr>

      <div class="nomination-list">
        <ul> <!-- nomination list -->
          <li v-for="(nomination, index) in film.nominations" :key="index" :class="nomination.won? 'li_win' : 'li_nom'">
            <small>
              <a @click="$emit('show-category', nomination.category.name, nomination.ceremony)" >
                {{ nomination.category.name }} | {{getCeremonyText(nomination.ceremony)}}
              </a>
              <br><i>{{ nomination.nominee }}</i>
            </small>
          </li>
        </ul>
      </div>
    </div>
  </div>

  <!-- placeholder glow -->
  <div v-else class="modal-body placeholder-glow" style="margin: 15px; text-align: left;">
    <div class="row" style="padding-bottom: 20px;">
      <div class="col-auto">
        <div class="placeholder poster-image"></div>
      </div>
      <div class="col small">
        <span class="placeholder col-11"></span>
        <span class="placeholder col-10"></span>
        <span class="placeholder col-10"></span>
        <span class="placeholder col-11"></span>
        <span class="placeholder col-12"></span>
        <span class="placeholder col-10"></span>
        <span class="placeholder col-8"></span>
      </div>
    </div>
    <hr>

    <div class="row">
      <div class="nomination-list">
        <small class="placeholder col-10"></small>
        <small class="placeholder col-8"></small>
        <small class="placeholder col-11"></small>
        <small class="placeholder col-8"></small>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import {Film} from "@/film";
import ViewChoiceIcons from "@/components/ViewChoiceIcons.vue";
import LanguageIcon from "@/components/LanguageIcon.vue";
import ViewedByFriendsCard from "@/components/friends/cards/ViewedByFriendsCard.vue";

export default {
  name: "ModalBodyForFilm",
  components: {ViewedByFriendsCard, LanguageIcon, ViewChoiceIcons},
  props: ['film_name', 'film_id'],
  emits: ['show-category'],

  data() {
    return {
      film: null,
      viewed_by_friends: [],
      axiosAbortcontroller: null
    }
  },

  mounted() {

    this.$gtag.event('film_modal_viewed', {
      film_id: this.film_id
    });

    this.axiosAbortcontroller = new AbortController();

    return axios.get(`/api/film/${this.film_id}/details`, { signal: this.axiosAbortcontroller.signal } )
        .then((response) => {

          if ( !response.data ) {
            throw Error(`Unexpected response from /api/film for film ${this.film_id} ("${this.film_name}")`)
          }
          this.film = new Film(response.data);
        })

        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          this.axiosAbortcontroller = null;
        });
  },

  unmounted() {
    // if we're mid-download, cancel it
    if ( this.axiosAbortcontroller ) {
      this.axiosAbortcontroller.abort();
      this.axiosAbortcontroller = null;
    }
  },

  methods: {
    getCeremonyText(_ceremony) {
      return Film.getCeremonyHeaderText(_ceremony);
    }
  }
}
</script>

<style scoped>

  .modal-title {
    padding-left: 10px;
  }
  .poster-image {
    float: left;
    margin: 0 15px 0 0;
    width:100px!important;
    height:150px!important;
    object-fit:cover;
  }

  .language-icon {
    padding-right: 5px;
  }

  .imdb-link {
    text-align: right;
    float: right;
  //background-color: red;
  }

  a {
    text-decoration: inherit;
    color: inherit;
    cursor: pointer;
  }
  a:hover {
    color: var(--bs-link-hover-color)!important;
  }

  .nomination-list {
    font-weight: 350;
    font-size: small;
  //margin-left: 10px;
  }

  ul {
    padding-left: 10px;
  }

  li {
    text-align: left;
    list-style: none;
    margin: 10px 0 0 0;
    padding: 0px 0 0px 20px;
    list-style: none;
    background-repeat: no-repeat;
    background-position-x: 0px;
    background-position-y: 4px;
    background-size: 14px;
  }

  .li_win {
    background-image: url('@/../public/win_icon.svg');
  }

  .li_nom {
    background-image: url('@/../public/nom_icon.svg');
  }


</style>