<template>
  <div class="container">
  <div class="row">
    <div class="offset-sm-2 col-sm-8 offset-md-2 col-md-8 offset-lg-3 col-lg-6 offset-xl-4 col-xl-4">

      <div class="card bg-light text-center">

        <div class="card-body">
          <h3 v-if="createAccount" class="card-title text-dark">Sign Up</h3>
          <h3 v-else class="card-title-info">Log In</h3>
          <hr class="sign-in-horizontal-rule">

          <div class="auth-provider-button">
            <Google :createAccount="createAccount"/>
          </div>

          <div class="auth-provider-button">
            <Apple :createAccount="createAccount"/>
          </div>

          <div>
            <EmailAndPassword :createAccount="createAccount" @user-updated="userUpdated"/>
          </div>

          <div class="sign-in-sign-up">
            <hr>
            <div v-if="createAccount">
                <p>Already have an account?</p>
                <a href="#" @click.prevent="toggleCreateAccount">Sign In</a>
            </div>
            <div v-else>
              First time here?
              <a href="#" @click.prevent="toggleCreateAccount">Sign Up</a>
            </div>
          </div>
        </div>

      </div>
    </div>
  </div>
  </div>
</template>


<script>
import EmailAndPassword from "@/components/auth/EmailAndPassword.vue";
import Google from "@/components/auth/Google.vue";
import Apple from "@/components/auth/Apple.vue";
import {userStore} from "@/userStore";

export default {
  name: "SignIn",
  emits: ['user-updated'],
  components: { EmailAndPassword, Google, Apple },
  computed: {
    user() {
      return userStore.user;
    }
  },
  created() {
    // if this component isn't on the LogInPage, save where we are so we can try coming back here
    if (this.$route.name !== 'LogInPage') {
      console.log(this.$route.name);
      sessionStorage.redirectAfterLogin = this.$route.path;
    }
  },
  data() {
    return {
      createAccount: false
    }
  },
  methods: {
    toggleCreateAccount() {
      this.createAccount = !this.createAccount;
    },
    userUpdated(_user) {
      userStore.setUser(_user);
      this.$emit('user-updated', _user);
    }
  }
}
</script>


<style scoped>
.auth-provider-button {

  margin-bottom: 20px;
}
.sign-in-horizontal-rule {
  padding-bottom: 10px;
}
.sign-in-sign-up {
  padding-top: 50px;
}

</style>
