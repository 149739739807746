
const DayIndex = Object.freeze({
    SUNDAY: 0,
    MONDAY: 1,
    TUESDAY: 2,
    WEDNESDAY: 3,
    THURSDAY: 4,
    FRIDAY: 5,
    SATURDAY: 6
});
const MonthIndex = Object.freeze({
    JANUARY: 0,
    FEBRUARY: 1,
    MARCH: 2,
    APRIL: 3,
    MAY: 4,
    JUNE: 5,
    JULY: 6,
    AUGUST: 7,
    SEPTEMBER: 8,
    OCTOBER: 9,
    NOVEMBER: 10,
    DECEMBER: 11,
});

function isDateTheNthWeekdayOfTheMonth(_date, _n, _weekday) {

    // early return if the weekday doesn't match
    if ( _date.getDay() !== _weekday ) {
        return false;
    }
    const firstDayOfMonth = new Date(_date.getFullYear(), _date.getMonth(), 1).getDay();
    const mod = (_weekday - firstDayOfMonth) % 7;
    const daysTilFirstGivenWeekday = mod < 0 ? mod + 7 : mod;
    const dateOfFirstGivenWeekday = 1 + daysTilFirstGivenWeekday;
    const dateOfNthGivenWeekday = dateOfFirstGivenWeekday + ((_n-1)*7);
    return (_date.getDate() === dateOfNthGivenWeekday);
}


// january

Date.prototype.isNewYearsDay = function() {
    return (
        (this.getMonth() === MonthIndex.JANUARY) &&
        (this.getDate() === 1)
    );
};

Date.prototype.isMlkDay = function() {
    // early out if the month is wrong
    if ( this.getMonth() !== MonthIndex.JANUARY ) {
        return false;
    }
    // mlk day is the THIRD monday in january
    return isDateTheNthWeekdayOfTheMonth(this, 3, DayIndex.MONDAY);
};

// february
Date.prototype.isGroundhogDay = function() {
    return (
        (this.getMonth() === MonthIndex.FEBRUARY) &&
        (this.getDate() === 2)
    );
};

Date.prototype.isWashingtonsBirthday = function() {
    // early out if the month is wrong
    if ( this.getMonth() !== MonthIndex.FEBRUARY ) {
        return false;
    }
    // washington's birthday is the THIRD monday in february
    return isDateTheNthWeekdayOfTheMonth(this, 3, DayIndex.MONDAY);
};

Date.prototype.isPresidentsDay = function() {
    return this.isWashingtonsBirthday();
};

Date.prototype.isValentinesDay = function() {
    return (
        (this.getMonth() === MonthIndex.FEBRUARY) &&
        (this.getDate() === 14)
    );
};

Date.prototype.isLeapDay = function() {
    return (
        (this.getMonth() === MonthIndex.FEBRUARY) &&
        (this.getDate() === 29)
    );
};

// march

Date.prototype.isStPatricksDay = function() {
    return (
        (this.getMonth() === MonthIndex.MARCH) &&
        (this.getDate() === 17)
    );
};

// april

// Easter is complicated, and doesn't always fall in the same month.
//  I got this algorithm here: https://gist.github.com/johndyer/0dffbdd98c2046f41180c051f378f343
Date.prototype.isEaster = function() {

    const year = this.getFullYear();

    // Golden Number - 1
    const G = year % 19;
    const C = Math.floor(year / 100);

    // related to Epact
    const H = (C - Math.floor(C / 4) - Math.floor((8 * C + 13)/25) + 19 * G + 15) % 30;

    // number of days from 21 March to the Paschal full moon
    const I = H - Math.floor(H/28) * (1 - Math.floor(29/(H + 1)) * Math.floor((21-G)/11));

    // weekday for the Paschal full moon
    const J = (year + Math.floor(year / 4) + I + 2 - C + Math.floor(C / 4)) % 7;

    // number of days from 21 March to the Sunday on or before the Paschal full moon
    const L = I - J;

    const easterMonth = (3 + Math.floor((L + 40)/44)) - 1;
    const easterDay = L + 28 - 31 * Math.floor((easterMonth+1) / 4);

    return (
        (this.getMonth() === easterMonth) &&
        (this.getDate() === easterDay)
    );
};


// may
Date.prototype.isMothersDay = function() {
    // mother's day is the 2nd sunday in may
    return (
        ( this.getMonth() === MonthIndex.MAY ) &&
        isDateTheNthWeekdayOfTheMonth(this, 2, DayIndex.SUNDAY)
    );
};

Date.prototype.isMemorialDay = function() {
    // early out if the month is wrong
    if ( this.getMonth() !== MonthIndex.MAY ) {
        return false;
    }
    // memorial day is the LAST monday in may...
    //  that means in one week it should be the FIRST day of JUNE
    const oneWeekFromDate = new Date(this);
    oneWeekFromDate.setDate(this.getDate() + 7);

    // early out if next week isn't june
    if ( oneWeekFromDate.getMonth() !== MonthIndex.JUNE ) {
        return false;
    }
    return isDateTheNthWeekdayOfTheMonth(oneWeekFromDate, 1, DayIndex.MONDAY);
};

// june
Date.prototype.isFathersDay = function() {
    // father's day is the 3rd sunday in june
    return (
        ( this.getMonth() === MonthIndex.JUNE ) &&
        isDateTheNthWeekdayOfTheMonth(this, 3, DayIndex.SUNDAY)
    );
};

Date.prototype.isJunteenth = function() {
    return (
        (this.getMonth() === MonthIndex.JUNE) &&
        (this.getDate() === 19
        )
    );
};

// july
Date.prototype.isIndependenceDay = function() {
    return (
        (this.getMonth() === MonthIndex.JULY) &&
        (this.getDate() === 4)
    );
}

// august



// september
Date.prototype.isLaborDay = function() {
    // labor day is the 1st monday in september
    return (
        ( this.getMonth() === MonthIndex.SEPTEMBER ) &&
        isDateTheNthWeekdayOfTheMonth(this, 1, DayIndex.MONDAY)
    );
};

// october
Date.prototype.isColumbusDay = function() {
    // columbus day is the 2nd monday in october
    return (
        ( this.getMonth() === MonthIndex.OCTOBER ) &&
        isDateTheNthWeekdayOfTheMonth(this, 2, DayIndex.MONDAY)
    );
};

Date.prototype.isIndigenousPeoplesDay = function() {
    // indigenous peoples day is the same day as columbus day
    return this.isColumbusDay();
};

Date.prototype.isHalloween = function() {
    return (
        (this.getMonth() === MonthIndex.OCTOBER) &&
        (this.getDate() === 31)
    );
}


// november
Date.prototype.isVeteransDay = function() {
    return (
        (this.getMonth() === MonthIndex.NOVEMBER) &&
        (this.getDate() === 11)
    );
}

Date.prototype.isThanksgiving = function() {
    // thanksgiving is the FOURTH thursday in november
    return (
        ( this.getMonth() === MonthIndex.NOVEMBER ) &&
        isDateTheNthWeekdayOfTheMonth(this, 4, DayIndex.THURSDAY)
    );
};

Date.prototype.isBlackFriday = function() {
    // black friday is the day after thanksgiving...
    //  so see if yesterday was thanksgiving
    const yesterday = new Date(this);
    yesterday.setDate(yesterday.getDate()-1);
    return yesterday.isThanksgiving();
}

// december
Date.prototype.isChristmas = function() {
    return (
        (this.getMonth() === MonthIndex.DECEMBER) &&
        (this.getDate() === 25)
    );
};

Date.prototype.isNewYearsEve = function() {
    return (
        (this.getMonth() === MonthIndex.DECEMBER) &&
        (this.getDate() === 31)
    );
};
