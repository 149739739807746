<template>
  <div class="container">

    <!--        top title -->
    <h1 class="text-center logo-themed-header">
      <img :src="`${publicPath}logo.svg`" alt="Logo" class="logo-image">
      <span class="text-my-oscar-list">MY<span class="text-oscar">OSCAR</span>LIST</span>
    </h1>

    <!--        checklist -->
    <div class="row justify-content-center">
      <div class="col-sm-10 col-md-8 col-lg-6 col-xl-5 col-xxl-4 feature-section feature-section-checklist">
        <h4 class="feature-section-title text-my-oscar-list">MARK<span class="text-oscar">YOUR</span>FILMS</h4>
        <p>
          <router-link class="login-link" to="/log-in">Log in</router-link> to access your personal checklist of every film ever nominated for an Academy Award.
        </p>
        <img class="checklist-image" :src="`${publicPath}about/checklist.png`">
        <div>
          <p><img class="checkmark-image" :src="`${publicPath}about/checkmark.png`"> Check the films you've seen.</p>
          <p><img class="checkmark-image" :src="`${publicPath}about/bookmark.png`"> Bookmark the ones you want to watch next.</p>
          <p><img class="checkmark-image" :src="`${publicPath}about/friend.png`"> See which films your friends have seen.</p>
        </div>
      </div>
    </div>


    <!--        dashboard -->
    <div class="row justify-content-center">
      <div class="col-sm-10 col-md-8 col-lg-6 col-xl-5 col-xxl-4 feature-section feature-section-dashboard">
        <h4 class="feature-section-title text-my-oscar-list">TRACK<span class="text-oscar">YOUR</span>PROGRESS</h4>
        <img class="dashboard-image" :src="`${publicPath}about/dashboard.png`">
        <p>
          View your dashboard, featuring unique fitness-ring-style progression meters and achievements.
        </p>
      </div>
    </div>


    <!--        detail modals -->
    <div class="row justify-content-center">
      <div class="col-sm-10 col-md-8 col-lg-6 col-xl-5 col-xxl-4 feature-section">
        <h4 class="feature-section-title text-my-oscar-list">GET<span class="text-oscar">THE</span>DETAILS</h4>
        <div class="row">
          <div class="col-6">
            <img class="film-details-image" :src="`${publicPath}about/film-details.png`">
            <div style="margin-top: 1em;">
              <p><img class="checkmark-image" :src="`${publicPath}win_icon.svg`"> Explore each film's nominations and wins.</p>
              <p>Follow a nomination's link to see which other films were competing in that category.</p>
            </div>
          </div>
          <div class="col-6">
            <p>Dive into the details of each film, including a short description, genres, running time, and if this film is in a foreign language.</p>
            <img class="film-details-image" :src="`${publicPath}about/category-details.png`">
          </div>
        </div>

      </div>
    </div>


    <!--        viewing options -->
    <div class="row justify-content-center">
      <div class="col-sm-10 col-md-8 col-lg-6 col-xl-5 col-xxl-4 feature-section">
        <h4 class="feature-section-title text-my-oscar-list">FIND<span class="text-oscar">YOUR</span>FILMS</h4>
        <div class="row">
          <div class="col-6">
            <img class="film-details-image" :src="`${publicPath}about/viewing-options.png`">
          </div>

          <div class="col-6">
            <div style="margin-top: 1em;">
              <p>Find which nominated films are coming up in the <img class="inline-logo-image" :src="`${publicPath}logos/content-providers/large-logos/tcm-logo.svg`"> schedule, as well as which are currently available on the most popular streaming services.</p>
              <div class="row">
                <div v-for="(imgName) in streamingServiceLogoImages" :key="imgName" class="col-4" style="margin-bottom:1cap;">
                  <img class="streaming-service-logo-image" :src="`${publicPath}logos/content-providers/large-logos/${imgName}`">
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>

    <div class="row justify-content-center">
      <div class="col-sm-10 col-md-8 col-lg-6 col-xl-5 col-xxl-4 feature-section">
        <h4 class="feature-section-title text-my-oscar-list">START<span class="text-oscar">YOUR</span>LIST</h4>
        <div style="text-align: center;">
          <router-link class="btn btn-primary btn-login" to="/log-in">
            log in
          </router-link>
          to get started now.
        </div>

      </div>
    </div>

  </div>
</template>


<script>



  export default {
    name: "WelcomePage",
    components: {
    },
    computed: {
      publicPath() {
        return process.env.BASE_URL;
      },
      streamingServiceLogoImages() {
        return [
          "amazon-prime-video-logo.svg",
          "apple-tv-plus-logo.svg",
          "disney-plus-logo.svg",
          "hulu-logo.svg",
          "kanopy-logo.svg",
          "max-logo.svg",
          "netflix-logo.svg",
          "paramount-plus-logo.svg",
          "peacock-logo.svg"
        ];
      }
    },
    methods: {
    }
  }

</script>


<style scoped>

.logo-themed-header {
  text-align: center;
  margin-top: 1cap;
  margin-bottom: 2cap;

  img {
    height: 1.5cap;
    margin-right: 0.1em;
    margin-top: -0.17cap;
  }
}

.login-link {
  text-decoration: none;
}

.feature-section {

  .feature-section-title {
    color: goldenrod;
    text-align: center;
    margin-top: 0.5em;
    margin-bottom: 1em;
  }

  background-color: white;

  p {
    text-align: center;
  }
  overflow: hidden;
  padding: 0 1em 0 1em;
  margin-bottom: 40px;

  max-width: 90%;
  border: solid;
  border-width: 3px;
  border-color: goldenrod;
  padding: 20px;

  &.feature-section-checklist {
  }

  &.feature-section-dashboard {
  }

}

/*
p {
  font-size: small;
}
 */


.checklist-image {
  display: block;
  width: 100%;
  max-width: 300px;
  margin-bottom: 1em;
  margin-left: auto;
  margin-right: auto;
}

.dashboard-image {
  width: 80%;
  display: block;
  margin-left: auto;
  margin-right: auto;

  margin-bottom: 1em;
}

.film-details-image {
  width: 100%;
}

.checkmark-image {
  height: 1.5cap;
}

.inline-logo-image {
  height: 1em;
}

.streaming-service-logo-image {
  width: 80%;
  display: block;
  margin-left: auto;
  margin-right: auto;
}


.text-my-oscar-list {
  font-weight: 300;

  .text-oscar {
    font-weight: 600;
    padding-left: 0.05em;
    padding-right: 0.05em;
  }
}






</style>