<template>
  <section class="ceremony-section container-fluid">

<!--  ceremony nav bar-->
    <ul ref="ceremonynavbar" class="nav fixed-top bg-light">
      <div v-for="(idx) in numCeremonies" :key="idx">
        <li class="nav-item" :class="{ active: ((numCeremonies+1)-idx === ceremony) }">
          <a @click="setCeremony((numCeremonies+1)-idx)" class="nav-link" aria-current="page" href="#">
            <img v-if="showNeedsReviewIndicator(((numCeremonies+1)-idx))" class="needs-review-badge" :src="`${publicPath}needs-review-badge.svg`"/>
            <div class="nav-ceremony-num">
              {{getCeremonyOrdinalText((numCeremonies+1)-idx)}}
            </div>
            <div class="nav-year">{{getYearOfCeremony((numCeremonies+1)-idx)}}</div>
          </a>
        </li>
      </div>
    </ul>

<!--  checklist -->

    <div class="row">
      <div class="ceremony col-12">
        <LoadingSpinner v-if="filmlist.length === 0" class="text-secondary" loading_text="loading" style="margin-top: 100px;"></LoadingSpinner>

        <div v-else>
          <div v-if="showCeremonyOverlay" class="ceremony-header text-secondary fixed-top col-12">
            {{ceremonyOrdinalText}} Ceremony • {{ceremonyDateText}}
          </div>

          <div class="checklist" ref="checklist">

            <!-- needs review sticky note -->
            <div style="position: relative;" v-if="showNeedsReviewIndicator(ceremony)">
              <img class="needs-review-sticky" :src="`${publicPath}needs-review-sticky.svg`"/>
            </div>

            <!-- checklist header -->
            <div class="year-header text-secondary">
              <div>{{ceremonyOrdinalText}} Ceremony • {{ceremonyDateText}}</div>
              <div>Honoring achievements in</div>
              <div>{{filmlist.length}} films from {{ceremonyYearsHonoredText(ceremony)}}</div>
            </div>

            <!-- the films -->
            <div class="container-fluid">
                <div v-for="(film, index) in filmlist" :key="film._id">
                  <hr v-if="index > 0" class="film-separator">
                  <ChecklistFilm class="film-card" :film=film :data-film-id=film._id></ChecklistFilm>
                </div>
            </div>
          </div>
        </div>
      </div>

    </div>

  </section>

</template>


<script>

import axios from "axios";
//import FilmChecklist from "@/components/FilmChecklist.vue";
import {Film} from "@/film";
//import FilmWithCheckbox from "@/components/FilmWithCheckbox.vue";
import ChecklistFilm from "@/components/ChecklistFilm.vue";
import LoadingSpinner from "@/components/LoadingSpinner.vue";
import {nextTick} from "vue";
import {userStore} from "@/userStore";
//import {EventBus} from "@/eventBus";
//import AchievementGrapic from "@/components/AchievementGrapic.vue";

export default {
  name: "ChecklistPage",
  components: {LoadingSpinner, ChecklistFilm},
  props: ['init_ceremony'],
  data() {
    return {
      FILM_REVIEWING_ENABLED: false,
      publicPath: process.env.BASE_URL,
      ceremony: null,
      numCeremonies: 0,
      filmlist: [],
      filmsReviewed: new Set(),
      filmReviewTimers: new Map(),
      categoryMap: new Map(),
      ceremonyOverview: null,
      showCeremonyOverlay: false
    }
  },
  async mounted() {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'instant',
    });
    window.addEventListener("scroll", this.onScroll, true);

    axios.get('/api/ceremony/overview')

        .then((result) => {
          this.ceremonyOverview = result.data;

          // put most recent ceremonies first
          this.ceremonyOverview.sort((cer1, cer2) => {
            return (cer1.ceremony < cer2.ceremony) ? 1 : (cer1.ceremony > cer2.ceremony) ? -1 : 0;
          });

          this.numCeremonies = this.ceremonyOverview.length;

          // give it a tick to render before trying to scroll
          return nextTick();
        })

        .then(() => {
          const initial_ceremony = this.init_ceremony? Number(this.init_ceremony) : this.numCeremonies;

          const init_scroll_percent = 1.0 - (initial_ceremony / this.numCeremonies);
          const init_scroll_pixels = (this.$refs.ceremonynavbar.scrollWidth * init_scroll_percent) - 20;

          this.$refs.ceremonynavbar.scrollTo({
            top: 0,
            left: init_scroll_pixels,
            behavior: 'instant',
          });
          return this.setCeremony( ((initial_ceremony > 0) && (initial_ceremony < this.numCeremonies))? initial_ceremony : this.numCeremonies );
        })

        .catch((err) => {
          console.log(err);
        });
  },
  updated() {
    this.handleChecklistReviewed();
  },
  beforeUnmount() {
    window.removeEventListener("scroll", this.onScroll, true)
  },
  computed: {
    ceremonyOrdinalText() {
      return Film.getCeremonyOrdinalText(this.ceremony);
    },
    ceremonyDateText() {
      const kMonthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
      const date = Film.getDateOfCeremony(this.ceremony);
      return `${kMonthNames[date.getUTCMonth()]} ${date.getUTCDate()} ${date.getUTCFullYear()}`;
    }
  },
  methods: {
    async setCeremony(_ceremony) {
      //console.log(`setCeremony(${_ceremony})`)

      if ( (_ceremony > 0) && (_ceremony <= this.numCeremonies) && (_ceremony != this.ceremony) ) {
        this.ceremony = _ceremony;
        this.filmlist = [];

        axios.get(`/api/ceremony/${_ceremony}/films`)
            .then((response) => {
              this.filmlist = response.data.map(f => new Film(f));
              this.filmsReviewed = new Set();
              Film.sortFilmsByPrioirity(this.filmlist);
              this.populateCategoryMap();

              if ( !this.FILM_REVIEWING_ENABLED || userStore.isCeremonyReviewed(_ceremony) ) {
                //console.log(`ceremony ${_ceremony} reviewed`)
                this.filmsReviewed = new Set(this.filmlist);
                window.removeEventListener("scroll", this.onScroll, true);

              } else {
                //console.log(`ceremony ${_ceremony} not reviewed`)
                this.filmsReviewed = new Set();
                window.addEventListener("scroll", this.onScroll, true);
              }

            })

            .catch((err) => {
              console.log(err);
            });
      }
    },

    populateCategoryMap() {

      // create a map where
      //  key =>  name of nomination category
      //  value => array of { film_id, won } objects
      this.categoryMap = new Map();
      this.filmlist.forEach((film) => {
        film.nominations.forEach((nom) => {
          const newValue = {
            film_id: film._id,
            won: nom.won
          };
          let films = this.categoryMap.has(nom.category.name)? this.categoryMap.get(nom.category.name) : [];
          if ( newValue.won ) {
            films.unshift(newValue);
          } else {
            films.push(newValue);
          }
          this.categoryMap.set(nom.category.name, films);
        });
      });
      //console.log(this.categoryMap);
    },

    ceremonyYearsHonoredText(_ceremony) {
      return Film.getCeremonyYearText(_ceremony);
    },

    getCeremonyOrdinalText(_ceremony) {
      return Film.getCeremonyOrdinalText(_ceremony);
    },

    getYearOfCeremony(_ceremony) {
      const date = Film.getDateOfCeremony(_ceremony);
      return date.getUTCFullYear();
    },

    showNeedsReviewIndicator(_ceremony) {
      return this.FILM_REVIEWING_ENABLED && !userStore.isCeremonyReviewed(_ceremony);
    },

    onScroll(/*e*/) {
      this.handleCeremonyOverlay();
      this.handleChecklistReviewed();
    },

    handleCeremonyOverlay() {
      this.showCeremonyOverlay = (window.top.scrollY > 60);
    },

    handleChecklistReviewed() {

      // early out if this ceremony has already been reviewed
      if ( !this.FILM_REVIEWING_ENABLED || userStore.isCeremonyReviewed(this.ceremony) ) {
        return;
      }

      const filmCardElements = document.querySelectorAll(".film-card");
      filmCardElements.forEach((filmCardElement) => {
        if (this.isElementInViewport(filmCardElement)) {
          this.elementInView(filmCardElement);
        } else {
          this.elementNotInView(filmCardElement);
        }
      });
    },

    elementInView(_element) {
      const filmId = _element.getAttribute('data-film-id');
      if ( !this.filmsReviewed.has(filmId) && !this.filmReviewTimers.has(filmId) ) {
        //console.log(`>> ${filmId}`)
        this.filmReviewTimers.set(filmId, setTimeout(this.markFilmReviewed, 250, filmId));
        _element.classList.add('shimmer');
      }
    },

    elementNotInView(_element) {
      const filmId = _element.getAttribute('data-film-id');
      if ( this.filmReviewTimers.has(filmId) ) {
        //console.log(`<< ${filmId}`)
        clearTimeout( this.filmReviewTimers.get(filmId) );
        this.filmReviewTimers.delete(filmId);
        _element.classList.remove('shimmer');
      }
    },

    markFilmReviewed(_film_id) {

      if ( this.filmsReviewed.size === this.filmlist.length ) {
        return;
      }

      //console.log(`***** ${_film_id}`)
      this.filmsReviewed.add(_film_id);

      if ( this.filmsReviewed.size === this.filmlist.length ) {
        this.markCeremonyReviewed();
      }
    },

    markCeremonyReviewed() {
      //console.log(`********* all films reviewed ************`);
      //this.$refs.checklist.classList.add('shimmer');

      axios.post(`/api/user/ceremony-reviewed/${this.ceremony}`)
          .then((response) => {
            // If, for some reason, we're trying to mark a ceremony as reviewed
            //  that already IS marked reviewed, we return a 204 (no content).
            if ( response.status === 200 ) {
              userStore.setUser(response.data);

              this.$gtag.event('ceremony_reviewed', {
                ceremony: this.ceremony
              });
            }
          })
          .catch((err) => {
            console.log(err);
          });
    },


    isElementInViewport(el) {
      const rect = el.getBoundingClientRect();
      return (
          rect.top >= 0 &&
          rect.left >= 0 &&
          //rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
          rect.top <= (window.innerHeight || document.documentElement.clientHeight) &&
          rect.right <= (window.innerWidth || document.documentElement.clientWidth)
      );
    },
  }
}
</script>


<style scoped>

.ceremony-section {
  background-color: FloralWhite;
  min-height: 100vh;
}

ul {
  display: flex;
  flex-wrap: nowrap;
  padding-bottom: 1.5rem;
  padding-left: 0;
  margin-block: 0;
  overflow-x: auto;
  list-style: none;
  text-align: center;
  white-space: nowrap;
}

.nav {

  margin-top: 60px;
  padding-top: 20px;
  z-index: 3;
  overflow-y: hidden;
  scroll-behavior: smooth;

  .nav-item {
    /*
    border: solid;
    border-width: 1px;
    border-color: lightgrey;
    margin-left: 3px;
    margin-right: 3px;
    background-image: url(../../public/backgrounds/paper-texture.jpg);
     */

    line-height: 15px;
    position: relative;

    &.active {
      font-weight: 700;
    }

    .needs-review-badge {
      position: absolute;
      width: 1em;
      box-shadow: 0px 0.5px 1px lightgrey;
      /*
      rotate: -10deg;
      top: 2%;
      left: 0%;
       */
      rotate: 10deg;
      top: 0%;
      left: 75%;

    }
    .nav-ceremony-num {
    }
    .nav-year {
      font-size: small;
    }
    .active {
      font-weight: 700;
      /*
      box-shadow: 0px 0px 20px 1px lightgrey;
      border-style: double;
      border-width: 5px;
      margin-top: -5px;
      margin-left: -2px;
      margin-right: -2px;
       */
    }
    a {
      color: inherit;
    }
    .badge {
      font-size: 50%;
      vertical-align: top;
    }
  }
}

.ceremony {
  font-family: "Courier New";

  padding-top: 20px;
  padding-bottom: 50px;
  width: fit-content;
  margin-right: auto;
  margin-left: auto;
}

.ceremony-header {

  margin-top: 150px;
  margin-left: auto;
  margin-right: auto;
  width: fit-content;

  font-weight: bold;
  z-index: 4;

  /* color | offset-x | offset-y | blur-radius */
  //text-shadow: floralwhite 1px 1px 10px;

  /* box-shadow: x y blur spread color */
  box-shadow: 0px 0px 20px 1px lightgrey;

  border-radius: 10px;
  border-color: lightgrey;
  border-style: solid;
  border-width: thin;
  padding: 5px 15px 5px 15px;

  background-image: url(../../public/backgrounds/paper-texture.jpg);
  animation: fadeIn 1s;
}


.checklist {
  max-width: 450px;
  margin: 90px 20px 50px 20px;
  padding-top: 20px;
  padding-bottom: 30px;
  border-radius: 0;
  z-index: 1;

  /*
  background-image: url(../../public/backgrounds/seamless_paper_texture.webp);
   */
  background-image: url(../../public/backgrounds/paper-texture.jpg);
  background-repeat:repeat;

  /* box-shadow: x y blur spread color */
  box-shadow: 3px 3px 5px 1px rgba(0, 0, 0, .1);

  .year-header {

    top: 140px;
    background-image: url(../../public/backgrounds/paper-texture.jpg);

    text-align: center;
    padding-top: 40px;
    margin-bottom: 30px;

    div:nth-child(1) {
      font-weight: bold;
    }
    div:nth-child(2) {
      font-size: smaller;
    }
    div:nth-child(3) {
      font-size: smaller;
    }
  }

  .film-separator {
    margin: 0px 20px 0px 20px;
    opacity: 10%;
  }
}

@keyframes fadeIn {
  0% {opacity: 0%;}
  100% {opacity: 100%;}
}

.shimmer {
  /*
  mask: linear-gradient(-60deg,#000 30%,#0005,#000 70%) right/300% 100%;
  -webkit-mask: linear-gradient(-60deg,#000 30%,#0005,#000 70%) right/300% 100%;
   */
  /*background-repeat: no-repeat;*/
  animation: shimmer 2s;
}

@keyframes shimmer {
  0% {opacity: 10%}
  100% {opacity: 100%}
  /*
  100% {mask-position:left}
  100% {-webkit-mask-position:left}
   */
}

.needs-review-sticky {
  z-index: 2;
  position: absolute;
  width: 20%;

  /* box-shadow: x y blur spread color */
  box-shadow: 0px 3px 5px 1px rgba(0, 0, 0, .1);


  /*
  rotate: 10deg;
  left: 75%;
  top: 4em;
   */

  rotate: -10deg;
  left: 5%;

}

</style>