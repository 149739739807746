<template>
  <div v-if="shouldShowBookmark" class="btn btn-sm" type="button" @click="toggleBookmark()">
    <span v-if="waitingForResponse" class="fa fa-bookmark transition"></span>
    <span v-else-if="filmBookmarked" class="fa fa-bookmark"></span>
    <span v-else class="fa fa-bookmark-o"></span>
  </div>
</template>


<script>
import axios from "axios";
import { userStore } from "@/userStore";
import { EventBus } from "@/eventBus";

export default {
  name: "FilmBookmark",
  props: ['film_id'],
  data() {
    return {
      filmBookmarked: false,
      waitingForResponse: false
    }
  },
  created() {
    this.updateFilmBookmark();
    EventBus.on('bookmark-state-updated', (_film_id) => {
      if (this.film_id === _film_id) {
        this.updateFilmBookmark();
      }
    });
  },
  computed: {

    shouldShowBookmark() {
      // only show bookmarks for unviewed films
      return !userStore.isFilmMarkedViewed(this.film_id);
    }
  },
  methods: {
    updateFilmBookmark() {
      this.filmBookmarked = userStore.isFilmBookmarked(this.film_id);
    },

    async toggleBookmark() {

      if ( this.waitingForResponse ) {
        return;
      }

      this.waitingForResponse = true;
      axios.post(`/api/user/watchlist/${this.film_id}/${!this.filmBookmarked}`)
          .then((response) => {
            // If, for some reason, we're trying to bookmark a film that's already bookmarked, we return a 204 (no content).
            if ( response.status === 200 ) {
              userStore.setUser(response.data);
              this.updateFilmBookmark();

              this.$gtag.event(this.filmBookmarked? 'film_bookmarked' : 'film_unbookmarked', {
                film_id: this.film_id
              });

              EventBus.emit('bookmark-state-updated', this.film_id);
            }
          })
          .catch((err) => {
            console.log(err);
          })
          .finally(() => {
            this.waitingForResponse = false;
          })
    }
  }
}
</script>


<style scoped>
.btn {
  vertical-align: top;
  border: none;
  height: 17px !important;
  width: 16px !important;
  .fa {
    color: coral;
    position: absolute;
    margin-top: -2px;
    margin-left: -6px;

    &.transition {
      animation: pulse 250ms infinite;
    }
    &.fa-bookmark-o {
      color: rgba(0, 0, 0, .15);

      &:hover {
        color: coral;
      }
    }

  }
}

.btn:hover {
  color: var(--bs-btn-color);
  background-color: var(--bs-btn-bg);
}



@keyframes pulse {
  0% {opacity: 55%;}
  50% {opacity: 45%;}
  100% {opacity: 55%;}
}

</style>