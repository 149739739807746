<template>

  <div class="container">
    <div v-if="user">
<!-- Ad for 2024 broadcast of 96th ceremony      -->
<!--      <a href="https://abc.com/shows/oscars" target='blank'>-->
<!--        <img class="banner-image" :src="`${this.publicPath}promos/2024OscarsOnABC.png`">-->
<!--      </a>-->

      <div v-if="ceremonyOverview" class="upcoming-ceremony">
        <h5 class="text-secondary upcoming-ceremony-header">
          97th Ceremony • Mar 2 2025
        </h5>
        <b>Shortlists Announcement</b>
        <p>Tuesday, December 17, 2024</p>
        <b>Nominations Announcement</b>
        <p>Friday, January 17, 2025</p>
        <b>97th Oscars</b>
        <p>Sunday, March 2, 2025<br>7pm EST / 4pm PST on ABC</p>
      </div>

      <LoadingSpinner v-if="!ceremonyOverview" class="text-secondary" loading_text="loading" style="margin-top: 50px;"></LoadingSpinner>

      <!-- each ceremony -->
      <CeremonyCard v-else v-for="(ceremony_info, index) in ceremonyOverview" :key="index" :ceremony_info="ceremony_info">
      </CeremonyCard>

    </div>
  </div>
</template>

<script>


import axios from "axios";
import {userStore} from "@/userStore";
import LoadingSpinner from "@/components/LoadingSpinner.vue";
import CeremonyCard from "@/components/dashboard/CeremonyCard.vue";

export default {
  name: "DashboardPage",
  components: {
    CeremonyCard,
    LoadingSpinner
  },
  computed: {
    user() {
      return userStore.user;
    }
  },
  data() {
    return {
      publicPath: process.env.BASE_URL,
      ceremonyOverview: null
    }
  },
  async created() {
    axios.get('/api/ceremony/overview')
        .then((result) => {
          this.ceremonyOverview = result.data;

          // put most recent ceremonies first
          this.ceremonyOverview.sort((cer1, cer2) => {
            return (cer1.ceremony < cer2.ceremony)? 1 : (cer1.ceremony > cer2.ceremony)? -1 : 0;
          });
          //console.log(this.ceremonyOverview);
        })

        .catch((err) => {
          console.log(err);
        });
  },
  methods: {

    firstHalfOfArray(_array) {
      const half = Math.ceil(_array.length / 2)
      return _array.slice(0, half);
    },

    secondHalfOfArray(_array) {
      const half = Math.ceil(_array.length / 2)
      return _array.slice(half);
    },

  }
}
</script>


<style scoped>

.banner-image {
  margin-top: -10px;
  padding-bottom: 30px;
  width: 100%;
}

.upcoming-ceremony {

  text-align: center;
  font-size: small;
  color: var(--bs-secondary);

  .upcoming-ceremony-header {
    text-align: center;
    margin-bottom: 1em;
    background-color: transparent;
    /*border: none;*/
  }

  margin-top: 10px;
  margin-bottom: 10px;
  margin-right: auto;
  margin-left: auto;
  max-width: 450px;

  padding: 15px 5px 5px 5px;

  border-style: solid none solid none;
  border-color: lightgrey;
  border-width: 1px;
}

</style>