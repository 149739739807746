import {ceremonies} from "@/ceremonies";

class StreamingProvider {

    constructor(apiResponseObj) {
        this.id = apiResponseObj.id;
        this.name = apiResponseObj.name;
        this.logo_path = apiResponseObj.logo_path;
    }
}

class Nomination {

    constructor(apiResponseObj) {
        this._id = apiResponseObj._id;
        this.name = apiResponseObj.name;
        this.ceremony = apiResponseObj.ceremony;
        this.nominee = apiResponseObj.nominee;
        this.category = apiResponseObj.category;
        this.won = apiResponseObj.won;
        this.display_priority = apiResponseObj.display_priority;
    }
}

export class FilmTitleInfo {
    constructor(apiResponseObj) {
        this._id = apiResponseObj._id;
        this.name = apiResponseObj.name;
        this.year = apiResponseObj.year;
        this.display_priority = apiResponseObj.display_priority;

        if ( apiResponseObj.nomCount ) {
            this.nomCount = apiResponseObj.nomCount;
            this.winCount = apiResponseObj.winCount;
        } else if ( apiResponseObj.nominations ) {
            this.nomCount = apiResponseObj.nominations.length;
            this.winCount = apiResponseObj.nominations.filter(n => n.won).length;
        }
    }
    toString() {
        return `${this.name}: ${this.nominations.length} nominations`;
    }

    numNominations() {
        return this.nomCount;
    }
    numWins() {
        return this.winCount;
    }
}

export class Film {
    constructor(apiResponseObj) {
        this._id = apiResponseObj._id;
        this.name = apiResponseObj.name;
        this.year = apiResponseObj.year;
        this.poster_url = apiResponseObj.poster_url;
        this.imdb = apiResponseObj.imdb;
        this.tmdb = apiResponseObj.tmdb;
        this.display_priority = apiResponseObj.display_priority;
        this.release_date = new Date(apiResponseObj.release_date);
        this.original_language = apiResponseObj.original_language;
        this.runtime = Number(apiResponseObj.runtime);
        this.genres = apiResponseObj.genres;
        this.original_title = apiResponseObj.original_title;

        this.nominations = apiResponseObj.nominations.map(n => new Nomination(n));

        this.streamingProviders = [];
        if ( apiResponseObj.streamingProviders?.length > 0 ) {
            this.streamingProviders = apiResponseObj.streamingProviders?.map(p => new StreamingProvider(p));
        }
    }
    toString() {
        return `${this.name} (${this.year}): ${this.nominations.length} nominations`;
    }

    genreText(_seperatingCharacter) {
        let text = '';
        if ( !_seperatingCharacter ) {
            _seperatingCharacter = ' ';
        }

        this.genres.forEach((genre, index, array) => {
            text += genre;
            if ( index !== (array.length - 1) ) {
                text += _seperatingCharacter;
            }
        });
        return text;
    }

    runtimeText() {
        const hours = Math.floor(this.runtime / 60);
        const minutes = this.runtime % 60;
        return ( (hours > 0)? `${hours}h ${minutes}m` : `${minutes}m` );
    }

    numNominations() {
        return this.nominations.length;
    }
    numWins() {
        return this.wins().length;
    }

    wins() {
        return this.nominations.filter(n => n.won);
    }

    imdbLink() {
        if (!this.imdb.id) {
            const urlSafeMovieName = encodeURI(`"${this.name.replace(' ', '+')}"`);
            return `https://www.imdb.com/search/title/?title=${urlSafeMovieName}&release_date=${this.year},${this.year+1}`
        }
        return `https://www.imdb.com/title/${this.imdb.id}`;
    }

    nominationsForCategory(_category) {
        let noms = this.nominations.filter(nom => nom.category.name == _category);

        return noms.sort((nom1, nom2) => {
            if (nom1.won && !nom2.won) {
                return -1;
            }
            if (nom2.won && !nom1.won) {
                return 1;
            }
            return 0;
        });
    }

    static sortFilmsAlphabetically(filmlist) {
        filmlist.sort((film1, film2) => {

            let name1 = film1.name.toLowerCase();
            if (name1.startsWith('a ')) {
                name1 = name1.slice(2);
            }
            if (name1.startsWith('the ')) {
                name1 = name1.slice(4);
            }

            let name2 = film2.name.toLowerCase();
            if (name2.startsWith('a ')) {
                name2 = name2.slice(2);
            }
            if (name2.startsWith('the ')) {
                name2 = name2.slice(4);
            }

            if (name1 < name2) {
                return -1;
            }
            if (name2 < name1) {
                return 1;
            }
            return 0;
        });
    }

    static sortFilmsByPrioirity(filmlist) {

        filmlist.sort((film1, film2) => {

            // Instead of simply comparing two films' display_priorities, this looks through the nominations lists of
            //  each film, to more reasonably sort ties (like, two non-winning best picture nominees have the same
            //  display_priority; but the one that won best actor should definitely be treated as higher priority).
            for ( let i = 0; i < Math.min(film1.nominations.length, film2.nominations.length); i++ ) {
                if ( film1.nominations[i].display_priority < film2.nominations[i].display_priority ) {
                    return 1;
                } else if ( film1.nominations[i].display_priority > film2.nominations[i].display_priority ) {
                    return -1;
                }
            }
            return (film2.nominations.length - film1.nominations.length)

            //return (film2.display_priority - film1.display_priority);
        });

    }

    static sortFilmsChronologically(filmlist) {
        filmlist.sort((film1, film2) => {
            return (film1.year - film2.year);
        });
    }

    static getNominatedCategoriesForFilms(_filmlist) {
        let categories = [];
        _filmlist.forEach((film) => {
            film.nominations.forEach((nom) => {
                if ( !categories.includes(nom.category.name) ) {
                    categories.push(nom.category.name)
                }
            })
        });
        return categories;
    }

    static filterFilmsNominatedForCategory(_filmlist, _category) {

        return _filmlist.filter((film) => {
            return (film.nominations.filter(nom => nom.category.name == _category).length > 0);
        });
    }

    static getCeremonyHeaderText(_ceremony) {
        const ceremony_num = Number(_ceremony);
        if ( ceremony_num <= 0 ) {
            return '';
        }
        return `${Film.getCeremonyOrdinalText(ceremony_num)} (${Film.getCeremonyYearText(ceremony_num)})`
    }

    static getCeremonyOrdinalText(_ceremony) {
        const ceremony_num = Number(_ceremony);
        if ( ceremony_num <= 0 ) {
            return '';
        }
        return `${ceremony_num}${
            (ceremony_num % 10 == 1 && ceremony_num % 100 != 11) ? 'st' :
                (ceremony_num % 10 == 2 && ceremony_num % 100 != 12) ? 'nd' :
                    (ceremony_num % 10 == 3 && ceremony_num % 100 != 13) ? 'rd' :
                        'th'}`;
    }
    static getCeremonyYearText(_ceremony) {
        const ceremony_num = Number(_ceremony);
        return ( ceremony_num <= 0 )? '' : ( ceremony_num <= 6 )? `${1926 + ceremony_num}/${27 + ceremony_num}` : `${1927 + ceremony_num}`;
    }

    static getDateOfCeremony(_ceremony) {

        const dateOfGivenCeremony = ceremonies.find((obj) => {
            return ( obj.ceremony === Number(_ceremony) );
        });

        return new Date(dateOfGivenCeremony?.date);
    }

}