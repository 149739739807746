<template>
  <div class="container">
    <div v-if="user">

      <div class="d-grid gap-2 d-flex justify-content-end">
        <button class="btn btn-sm btn-outline-primary" type="button" @click="showFilterModal">
          <i class="fa fa-filter"></i> {{filterButtonText}}
        </button>
        <GenericModal v-if="show_filter_modal" @modal-closed="show_filter_modal=false">
          <template #header>
            <h6 style="text-align: center;"><i class="fa fa-filter"></i> filter films</h6>
          </template>

          <div class="row">
              <div v-for="(genre) in genreList" :key="genre" class="col-6 filter-toggle">
                <span class="btn btn-outline-secondary btn-sm btn-filter-checkbox" type="button" @click="toggleGenreFilter(genre)">
                  <span v-if="filteredByGenres.includes(genre)" class="fa fa-check" aria-hidden="true"></span>
                </span>
                <span :style="filteredByGenres.includes(genre)? 'font-weight: bolder;' : ''" @click="toggleGenreFilter(genre)"> {{genre}}</span>
              </div>
          </div>

          <hr>

          <div class="col-6 filter-toggle">
            <span class="btn btn-outline-secondary btn-sm btn-filter-checkbox" type="button" @click.stop="hideViewedFilms = !hideViewedFilms">
              <span v-if="hideViewedFilms" class="fa fa-check" aria-hidden="true"></span>
            </span>
            <span :style="hideViewedFilms? 'font-weight: bolder;' : ''" @click="hideViewedFilms = !hideViewedFilms"> <i class="fa fa-eye-slash"></i> hide viewed films</span>
          </div>
        </GenericModal>
      </div>

      <hr>

      <div class="row">
        <div class="col-xxl-3 col-lg-4 col-md-6">
          <ScheduledFilmListCard provider="tcm" :hideViewedFilms="hideViewedFilms" :filterByGenres="filteredByGenres">
            <img :src="logoPathTo('tcm-logo.svg')" alt="Logo" height="20">
          </ScheduledFilmListCard>
        </div>
        <div class="col-xxl-3 col-lg-4 col-md-6">
          <StreamingFilmListCard provider="Netflix" :hideViewedFilms="hideViewedFilms" :filterByGenres="filteredByGenres">
            <img :src="logoPathTo('netflix-logo.svg')" alt="Logo" height="20">
          </StreamingFilmListCard>
        </div>
        <div class="col-xxl-3 col-lg-4 col-md-6">
          <StreamingFilmListCard provider="Max" :hideViewedFilms="hideViewedFilms" :filterByGenres="filteredByGenres">
            <img :src="logoPathTo('max-logo.svg')" alt="Logo" height="20">
          </StreamingFilmListCard>
        </div>
        <div class="col-xxl-3 col-lg-4 col-md-6">
          <StreamingFilmListCard provider="Hulu" :hideViewedFilms="hideViewedFilms" :filterByGenres="filteredByGenres">
            <img :src="logoPathTo('hulu-logo.svg')" alt="Logo" height="20">
          </StreamingFilmListCard>
        </div>
        <div class="col-xxl-3 col-lg-4 col-md-6">
          <StreamingFilmListCard provider="Peacock Premium" :hideViewedFilms="hideViewedFilms" :filterByGenres="filteredByGenres">
            <img :src="logoPathTo('peacock-logo.svg')" alt="Logo" height="20">
          </StreamingFilmListCard>
        </div>
        <div class="col-xxl-3 col-lg-4 col-md-6">
          <StreamingFilmListCard provider="Disney Plus" :hideViewedFilms="hideViewedFilms" :filterByGenres="filteredByGenres">
            <img :src="logoPathTo('disney-plus-logo.svg')" alt="Logo" height="20">
          </StreamingFilmListCard>
        </div>
        <div class="col-xxl-3 col-lg-4 col-md-6">
          <StreamingFilmListCard provider="Paramount Plus" :hideViewedFilms="hideViewedFilms" :filterByGenres="filteredByGenres">
            <img :src="logoPathTo('paramount-plus-logo.svg')" alt="Logo" height="20">
          </StreamingFilmListCard>
        </div>
        <div class="col-xxl-3 col-lg-4 col-md-6">
          <StreamingFilmListCard provider="Apple TV Plus" :hideViewedFilms="hideViewedFilms" :filterByGenres="filteredByGenres">
            <img :src="logoPathTo('apple-tv-plus-logo.svg')" alt="Logo" height="20">
          </StreamingFilmListCard>
        </div>
        <div class="col-xxl-3 col-lg-4 col-md-6">
          <StreamingFilmListCard provider="Amazon Prime Video" :hideViewedFilms="hideViewedFilms" :filterByGenres="filteredByGenres">
            <img :src="logoPathTo('amazon-prime-video-logo.svg')" alt="Logo" height="20">
          </StreamingFilmListCard>
        </div>
        <div class="col-xxl-3 col-lg-4 col-md-6">
          <StreamingFilmListCard provider="Kanopy" :hideViewedFilms="hideViewedFilms" :filterByGenres="filteredByGenres">
            <img :src="logoPathTo('kanopy-logo.svg')" alt="Logo" height="20">
<!--            <span style="float: right; font-size: 9px; margin-top: -4px;" >-->
<!--              <a class="text-secondary" style="color: inherit; text-decoration: inherit; text-align: right;" href="https://www.kanopy.com/en/signup/find/publiclibrary" title='kanopy' target='blank'>-->
<!--                Free access to thousands of<br>movies with your library card-->
<!--                <sup><i style="" class='fa fa-external-link'></i></sup>-->
<!--              </a>-->
<!--            </span>-->
          </StreamingFilmListCard>
        </div>
      </div>
<!--      <FilmListCard title="viewed films" :film_ids="user.viewed"/>-->
    </div>
  </div>
</template>

<script>

import ScheduledFilmListCard from "@/components/ScheduledFilmListCard.vue";
import StreamingFilmListCard from "@/components/StreamingFilmListCard.vue";
import {userStore} from "@/userStore";
import GenericModal from "@/components/modal/GenericModal.vue";
import axios from "axios";

export default {
  name: "DashboardPage",
  components: {
    GenericModal,
    ScheduledFilmListCard,
    StreamingFilmListCard
  },
  data() {
    return {
      show_filter_modal: false,
      hideViewedFilms: false,
      filteredByGenres: [],
      savedFilterInfo: null,
    }
  },
  watch: {
    show_filter_modal(shown) {

      if ( shown ) {
        // when the filter modal is shown, save the filter info
        this.savedFilterInfo = {
          hideViewedFilms: this.hideViewedFilms,
          filteredByGenresSaved: this.filteredByGenres.map(genre => genre)
        }
      } else {
        // when the filter modal is hidden, send an analytics event if the current filter info is different from what we saved
        const currentFilterInfo = {
          hideViewedFilms: this.hideViewedFilms,
          filteredByGenresSaved: this.filteredByGenres
        }

        // test for object equality by comparing their JSON versions
        if ( (JSON.stringify(currentFilterInfo) !== JSON.stringify(this.savedFilterInfo)) ) {
          this.$gtag.event('viewing_options_filters_updated', {
            filteredByGenres: this.filteredByGenres,
            hideViewedFilms: this.hideViewedFilms
          });
        }

      }
    }
  },
  computed: {
    user() {
      return userStore.user;
    },
    numFiltersApplied() {
      return this.filteredByGenres.length + (this.hideViewedFilms? 1 : 0);
    },
    filterButtonText() {
      return (this.numFiltersApplied > 0)? `${this.numFiltersApplied} filters applied` : 'filter films';
    }
  },
  methods: {
    logoPathTo(_image) {
      return `${process.env.BASE_URL}logos/content-providers/large-logos/${_image}`;
    },

    toggleGenreFilter(_genre) {
      const genreIndex = this.filteredByGenres.indexOf(_genre);
      if ( genreIndex === -1 ) {
        this.filteredByGenres.push(_genre);
      } else {
        this.filteredByGenres.splice(genreIndex, 1);
      }
    },

    showFilterModal() {
      this.show_filter_modal = true;
    },
  },
  created() {
    axios.get('api/film/genres')
        .then((response) => {
          this.genreList = response.data;
        })
        .catch((err) => {
          console.log(err);
        });
  },

}
</script>


<style scoped>
  .options-card {
    margin-bottom: 20px;
  }

  .form-check-label {
    font-size: small;
  }

  .btn-toggle-viewed {

  }

  .btn-toggle-viewed:hover {
    color: var(--bs-btn-color);
    background-color: var(--bs-btn-bg);
  }

  .filter-toggle {
    margin-bottom: 1em;
  }

  .btn-filter-checkbox {

    margin-bottom: 0;
    margin-right: 0.4em;
    height: 17px !important;
    width: 16px !important;

    .fa-check {
      position: absolute;
      margin-top: -3px;
      margin-left: -7px;
    }
  }

</style>