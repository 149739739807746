<template>
  <span class="badge rounded-pill"><i class="fa fa-hourglass-1" aria-hidden="true"></i> invite sent</span>
  <FriendCard class="invite-sent-card" :friend="friend" :cancel_button_text="'cancel invitation'" @cancel-button-clicked="show_confirmation_modal=true">
  </FriendCard>

  <GenericModal v-if="show_confirmation_modal" @modal-closed="show_confirmation_modal=false">
    <div class="text-center">
      <div>
        <p>Are you sure you want to cancel this invite to <br><strong>{{friend.username}}</strong>?</p>
      </div>
      <button class="btn btn-sm btn-primary" type="button" data-bs-dismiss="modal">
        <i class="fa fa-user"></i> keep invitation
      </button>
      <button class="btn btn-sm btn-danger me-sm-2" type="button" data-bs-dismiss="modal" @click="cancelInvite()">
        <i class="fa fa-user-times"></i> cancel invitation
      </button>
    </div>
  </GenericModal>
</template>


<script>
import axios from "axios";
import {userStore} from "@/userStore";
import FriendCard from "@/components/friends/cards/FriendCard.vue";
import GenericModal from "@/components/modal/GenericModal.vue";

export default {
  name: 'FriendCard_InviteSent',
  components: {GenericModal, FriendCard},
  props: ['friend'],
  emits: ['update-friends-list'],
  data() {
    return {
      show_confirmation_modal: false
    }
  },
  methods: {
    async cancelInvite() {
      console.log(`cancelInvite()`)

      if ( !this.friend?._id ) {
        return;
      }
      axios.get(`/api/friend/remove-relationship-with/${this.friend._id}`)
          .then((response) => {
            userStore.setUser(response.data);
            console.log(`update-friends-list`)
            this.$emit('update-friends-list');
          })
          .catch((err) => {
            console.log(err);
          });
    },
  }

}
</script>


<style scoped>
button {
  margin-right: 5px;
  margin-left: 5px;
}

.badge {
  background-color: cornflowerblue;
  position: absolute;
  margin-top: -8px;
  margin-left: 12px;
  animation: pulse-animation 2s infinite;
  z-index: 1;
  i {
    font-size: x-small;
  }
}

.invite-sent-card {
  color: color-mix(in srgb, cornflowerblue, transparent 50%);
  border-color: color-mix(in srgb, cornflowerblue, transparent 50%);
  background-color: color-mix(in srgb, aliceblue, transparent 50%);


  .confirm-text {
    text-align: center;
    margin-bottom: 10px;

    p {
      color: cornflowerblue;
      margin-bottom: 0px;
      font-size: smaller;

      &.friend-name {
        font-weight: 500;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
      }
    }
  }
}

</style>