<template>

    <!-- film info -->
    <div v-if="films" style="margin: 15px">
      <div class="row">
        <div class="film-list">
            <div v-for="(film) in films" :key="film._id">

              <div class="row">

                <div class="col-auto" style="padding-right: 0px;">
                  <FilmCheckbox :film_id="film._id"></FilmCheckbox>
                </div>

                <div class="col" style="padding-right: 0px; text-align: left;">
                  <a :class="this.getFilmViewed(film._id)? 'viewed-film-text' : ''" @click="$emit('show-film', film._id, film.name, film.year)" >
                    {{film.name}}
                  </a>
                  <LanguageIcon class="language-icon" v-if="film.original_language !== 'en'" :language_code="film.original_language" :superscript="true"></LanguageIcon>
                  <FriendIconGroup :film_id="film._id"></FriendIconGroup>
                  <br>
                </div>
              </div>

              <ul>
                <li v-for="nomination in film.nominationsForCategory(category_name)" :key="nomination._id" :class="nomination.won? 'li_win' : 'li_nom'">
                  <small><i>{{nomination.nominee}}</i></small>
                </li>
              </ul>
            </div>
        </div>
      </div>
    </div>

  <!-- placeholder glow -->
  <div v-else class="placeholder-wave" style="margin: 15px">
    <div class="row">
      <div class="film-list">
        <div v-for="index in 6" :key="index">
          <p class="placeholder col-12"></p>
          <ul>
            <li>
              <small class="placeholder col-12"></small>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>

</template>

<script>
import axios from "axios";
import {Film} from "@/film";
import { userStore } from "@/userStore";
import FilmCheckbox from "@/components/FilmCheckbox.vue";
import LanguageIcon from "@/components/LanguageIcon.vue";
import FriendIconGroup from "@/components/friends/icons/FriendIconGroup.vue";

export default {
  name: "ModalBodyForCategory",
  components: {FriendIconGroup, LanguageIcon, FilmCheckbox},
  props: ['category_name', 'ceremony'],
  emits: ['show-film'],
  data() {
    return {
      films: null,
      axiosAbortcontroller: null,
      userStore
    }
  },
  created() {
    this.axiosAbortcontroller = new AbortController();

    return axios.get(`/api/ceremony/${this.ceremony}/films/${this.category_name}`, { signal: this.axiosAbortcontroller.signal } )
        .then((response) => {

          if ( !response.data ) {
            throw Error(`Unexpected response from /api/ceremony/${this.ceremony}/films/${this.category}`)
          }
          this.films = response.data.map(f => new Film(f));

          this.films.sort((film1, film2) => {
            if ( film1.nominationsForCategory(this.category_name)[0].won ) {
              return -1;
            }
            if ( film2.nominationsForCategory(this.category_name)[0].won ) {
              return 1;
            }
            return 0;
          });
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          this.axiosAbortcontroller = null;
        });
  },

  mounted() {
    this.$gtag.event('category_modal_viewed', {
      ceremony: this.ceremony,
      category: this.category_name
    });
  },

  unmounted() {
    // if we're mid-download, cancel it
    if ( this.axiosAbortcontroller ) {
      this.axiosAbortcontroller.abort();
      this.axiosAbortcontroller = null;
    }
  },

  methods: {

    getFilmViewed(_film_id)
    {
      return userStore.isFilmMarkedViewed(_film_id);
    }
  }
}
</script>

<style scoped>

  .modal-title {
    padding-left: 10px;
  }

  a {
    text-decoration: inherit;
    color: inherit;
    cursor: pointer;
  }
  a:hover {
    color: var(--bs-link-hover-color);
  }

  .viewed-film-text {
    text-decoration: line-through;
    opacity: 0.5;
    .fa {
      text-decoration: none;
    }
  }

  .film-list {
    font-weight: 350;
    font-size: small;
  //margin-left: 10px;
  }

  .language-icon {
    padding-left: 0.2em;
    opacity: 80%;
  }

  ul {
    padding-left: -10px;
  }

  li {
    text-align: left;
    list-style: none;
    margin: 0;
    padding: 0px 0 0px 20px;
    list-style: none;
    background-repeat: no-repeat;
    background-position-x: 0px;
    background-position-y: 4px;
    background-size: 14px;
  }

  .li_win {
    background-image: url('@/../public/win_icon.svg');
  }

  .li_nom {
    background-image: url('@/../public/nom_icon.svg');
  }


</style>